
    import gsap from '~/mixins/gsap';
    import {v4 as uuidv4} from 'uuid';
    export default {
        data() {
            return {
                isVisible:false,
                open: true,
                animating : false,
                uuidv4(){ return uuidv4() }
            }
        },
        mixins : [gsap],
        computed : {
            items : {
                get(){
                    return this.$store.state.compare.items;
                }
            },
            isDark(){
                return this.$store.state.layout.displayMode == 'dark';
            },
            products(){ return this.$store.state.compare.products},
            max(){ return this.$store.state.compare.max },
            path(){ return this.$route.path }
        },
        watch : {
            path(t,f){
                this.animate(!this.$route.path.includes("compare") && this.$route.path.includes("products") && !(parseInt(this.$route.path.split("/").pop()) > 0) && this.items.length > 0);
            },
            items(t,f){
                this.animate(this.items.length > 0);
            },
            animating(t,f){
                console.log("animating",t,f);
            },
        },
        methods: {
            animate(where){
                if(!this.animating){
                    this.animating = true;
                    if(where){
                        if(!this.isVisible) this.tl.play(); else this.animating = false;
                    }else{
                        if(this.isVisible) this.tl.reverse(); else this.animating = false;
                    }
                }
            },
            initGsapAnimations(){
                if(!this.$el || !this.$el.querySelector){
                    this.$lerpfunction(this.initGsapAnimations);
                }else{
                    this.tl = new this.gsap.timeline({paused:true,onReverseComplete:this.resetAnimating,onComplete:this.resetAnimating});
                    this.gsap.set(this.$el,{y:0,opacity:0});
                    this.tl.to(this.$el,{y:'-100%',opacity:1});
                }
            },
            resetAnimating(){
                this.isVisible = !this.isVisible;
                this.animating = false;
            }
        },
    }
