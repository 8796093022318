export default {
    data () {
        return {
            gsap: null,
            mountedInit : true,
            nextTickInit : true,
        }
    },
    mounted() {
        if(this.mountedInit){ 
            if(this.nextTickInit){ 
                this.$nextTick(() => { 
                    this.initGsap();
                })
            }else{
                this.initGsap(); 
            }
        }
        
    },
    methods: {
        initGsap () {
            var { Power4,gsap } = require('gsap');
            var { ScrollTrigger } = require('gsap/ScrollTrigger');
            var { ScrollToPlugin } = require('gsap/ScrollToPlugin');
            var { MotionPathPlugin } = require('gsap/MotionPathPlugin');
            gsap.registerPlugin(ScrollToPlugin, MotionPathPlugin, ScrollTrigger);
            this.gsap = gsap;
            this.Power4 = Power4;
            if(this.initGsapAnimations) this.initGsapAnimations();
        }
    }
}